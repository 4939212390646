import type { ReactElement } from 'react'
import React from 'react'

import type { LinkProps } from 'next/link'
import Link from 'next/link'
import styled from 'styled-components'

import type { CardHeadingProps } from './CardHeading'
import DetailedPreviewCardHeading from './CardHeading'

import AspectRatioBox from 'components/AspectRatioBox'
import type { ImageRatio } from 'components/AspectRatioBox/AspectRatioBox'
import Heading from 'components/Heading'
import { LinkWrapper } from 'components/Link/LinkWrapper'
import Paragraph from 'components/Paragraph'
import ContentLockedPill from 'components/Pill/ContentLockedPill'
import ContentTypePill from 'components/Pill/ContentTypePill'
import type { BoxProps } from 'components/Primitives/Box'
import Box from 'components/Primitives/Box'
import { ImageRatios } from 'consts/index'
import { routeMap } from 'shared/routes'
import type { ColorName, ThemedComponent } from 'theme'
import { fonts, zIndex } from 'theme'
import type { ContentPieceUIImageType, ContentPieceUITagsType } from 'types/contentPieceUI'

type Author = { name: string; slug?: string; isActive?: boolean }

export type DetailedPreviewCardProps = {
    title: string
    subtitle: string | null
    url: LinkProps['href']
    isLocked: boolean
    contentTypePublicName: string | null
    titleMaxLines?: CardHeadingProps['maxLines']
    titleSize?: CardHeadingProps['fontSize']
    subtitleMaxLines?: number
    author?: Author
    metaText?: string
    color?: ColorName
    // We should try to type the below more strongly once the algolia
    // component is also created
    image: ContentPieceUIImageType | null
    imageRatio?: ImageRatio
    tags: ContentPieceUITagsType | null
    bookmarkButton?: ReactElement
    onClick?: () => void
}

const detailedPreviewCardTestID = 'detailed-preview-card'

// This is a generic component, so should not contain any reference
// to a data source (e.g. relay)
export const DetailedPreviewCard = ({
    title,
    subtitle,
    url,
    author,
    titleMaxLines = 3,
    titleSize = fonts.size.L,
    subtitleMaxLines = 3,
    metaText,
    color = 'neutral.0',
    tags: Tags,
    isLocked,
    image: Image,
    imageRatio = ImageRatios.GOLDEN_RATIO,
    contentTypePublicName,
    bookmarkButton: BookmarkButton,
    onClick,
}: DetailedPreviewCardProps): ReactElement => {
    const authorName = author?.name
    const authorSlug = author?.slug
    const authorIsActive = author?.isActive

    // The position of the author + meta text is determined by the presence of the content type
    // If the content type pill is not shown (e.g. on a small card) then the author + meta text
    // is shown in line with the lock + bookmark icons, otherwise it is displayed above the tags at the bottom of the card.
    const displayContentType = !!contentTypePublicName
    const MetadataAndAuthor = (author || metaText) && (
        <MetadataContainer>
            <MetaText fontSize={fonts.size.XXS} color="neutral.500">
                {/* Display as a link if the slug exists and author is active */}
                {authorName &&
                    (authorSlug && authorIsActive ? (
                        <AuthorLink href={routeMap.experts(authorSlug)}>
                            <AuthorSpan>{authorName}</AuthorSpan>
                        </AuthorLink>
                    ) : (
                        <AuthorSpan>{authorName}</AuthorSpan>
                    ))}

                {authorName && metaText && ' • '}
                {metaText}
            </MetaText>
        </MetadataContainer>
    )

    return (
        <Container width="100%" height="100%" onClick={onClick} background={color}>
            <PillsContainer isOverlay={!!Image}>
                {!displayContentType && <>{MetadataAndAuthor}</>}
                {displayContentType && <ContentTypePill>{contentTypePublicName}</ContentTypePill>}

                <Icons>
                    {isLocked && <ContentLockedPill />}
                    {BookmarkButton}
                </Icons>
            </PillsContainer>
            <LinkWrapper url={url} dataCy={`${title}-${detailedPreviewCardTestID}`}>
                <>
                    {!!Image && (
                        <AspectRatioBox ratio={imageRatio} width="100%" className="image-overlay">
                            <>{Image}</>
                        </AspectRatioBox>
                    )}

                    <DetailWrapper>
                        <Box>
                            <DetailedPreviewCardHeading
                                maxLines={titleMaxLines}
                                fontSize={titleSize}
                            >
                                {title}
                            </DetailedPreviewCardHeading>

                            {subtitle && (
                                <Heading
                                    as="h4"
                                    maxLines={subtitleMaxLines}
                                    fontSize={fonts.size.XS}
                                    fontWeight={fonts.weight.normal}
                                    lineHeight={fonts.lineHeight.paragraph}
                                    color="neutral.500"
                                >
                                    {subtitle}
                                </Heading>
                            )}
                        </Box>
                    </DetailWrapper>
                </>
            </LinkWrapper>
            {displayContentType && <Box px="0.75rem">{MetadataAndAuthor}</Box>}
            {Tags && Tags.length > 0 && <TagsContainer>{Tags}</TagsContainer>}
        </Container>
    )
}

export default DetailedPreviewCard

type StyledContainerProps = ThemedComponent<BoxProps>

const Container = styled(Box)<StyledContainerProps>`
    width: 100%;
    height: 100%;
    gap: 0.75rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    padding-bottom: 0.75rem;
`

// If an image is shown, these pills will be overlayed on top of the image
const PillsContainer = styled(Box)<{ isOverlay: boolean }>`
    position: ${({ isOverlay }) => (isOverlay ? 'absolute' : 'relative')};
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding: 0.75rem;
    padding-bottom: 0;
    margin-bottom: -0.75rem;

    z-index: ${zIndex.layer01};
`

const Icons = styled(Box)`
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    margin-left: auto;
`

const DetailWrapper = styled(Box)`
    display: flex;

    flex-direction: column;
    justify-content: space-between;
    flex-flow: column wrap;
    flex-wrap: wrap;
    overflow: hidden;
    box-sizing: content-box;

    padding: 0.75rem;
    padding-bottom: 0;

    & h3 {
        margin: 0;
        margin-bottom: 0.5rem;
    }
    & h4 {
        margin-top: 0;
        margin-bottom: 0.5rem;
    }
`

const MetaText = styled(Paragraph)`
    margin: 0;
`

const AuthorSpan = styled.span`
    color: ${({ theme }) => theme.palette.neutral[999]};
`

const AuthorLink = styled(Link)`
    cursor: pointer;
    text-decoration: none;
    :hover {
        text-decoration: underline;
    }
`

const MetadataContainer = styled.div`
    display: flex;
    flex-direction: row;
`

const TagsContainer = styled(Box)`
    display: flex;
    align-items: center;

    justify-self: flex-end;

    gap: 0.5rem;
    padding: 0 0.75rem;
`
