import type { ReactElement, ReactNode } from 'react'

import Container from 'components/Container'

type Props = {
    children: ReactNode
    pageName: string
    hasTopPadding?: boolean
}

/**
 * Wrapper around page content to ensure we have consistent styling.
 */
const PaddedPageContainer = ({ children, pageName, hasTopPadding = true }: Props): ReactElement => {
    return (
        <Container
            data-cy={`${pageName}-page`}
            isFluid={true}
            paddingBottom={3}
            paddingTop={hasTopPadding ? [1, 3] : 0}
            maxWidth="100vw"
        >
            {children}
        </Container>
    )
}

export default PaddedPageContainer
